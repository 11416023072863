<template>
  <div id="linkGraphPane" ref="linkGraphPane">
    <G6SearchNode style="padding-left:30%;padding-top:2px;"/>
    <div ref="mountNode"></div>
  </div>
</template>

<script>
import G6 from '@antv/g6';
import axios from 'axios';
import G6SearchNode from '@/common/components/G6SearchNode.vue';
import graphConfig from '../../../config/graphConfig';
// import NodeForm from './NodeForm.vue';
// import DendroGramComp from './DendroGramComp.vue';

export default {
  components: {
    // DendroGramComp,
    // NodeForm,
    G6SearchNode,
  },
  data() {
    return {
      isLoading: false,
      toggleGraph: true,
      searchWord: '',
      content: '',
      formDataObj: {
        id: '',
        label: 'Node Label',
        description: 'Node Description',
        tags: 'Node Tags',
        type: 'circle',
        size: '20',
        color: '#FFFFFF',
      },
    };
  },

  computed: {
    g6_data() {
      return this.$store.state.g6_graph_data;
    },
    edit_mode() {
      return this.$store.state.gmodule.edit_mode;
    },
  },

  watch: {
    g6_data(val) {
      this.g6_graph.changeData(val);
    },
    edit_mode(val) {
      if (val === 'hsl') {
        this.g6_graph.setMode('hsl');
      } else {
        this.g6_graph.setMode('default');
      }
    },
  },

  async mounted() {
    await this.wikiGraphBuild();
    new ResizeObserver(this.resizeLayout).observe(this.$refs.linkGraphPane);

    this.$root.$on('g6NodeFocus', this.nodeSearchFocus);
    this.$root.$on('g6NodeSearch', this.nodeSearch);
    this.$root.$on('g6NodeSearchClear', this.nodeSearchClear);
    this.$root.$on('filterNodes', this.nodeEdgeFilter);
  },

  async created() {
    await this.$highway.singleDispatcher('wikiGraphAction');
    this.g6_graph.changeData(this.$store.state.g6_graph_data);
    this.$graphLayoutConfig.loadGraphConfigs(this.g6_graph);
  },

  beeforeDestroy() {
    this.$root.$off('g6NodeSearch');
    this.$root.$off('g6NodeSearchClear');
    this.$root.$off('g6NodeFocus');
    this.$root.$off('filterNodes');
  },

  methods: {
    resizeLayout() {
      this.g6_graph.changeSize(this.$refs.linkGraphPane.scrollWidth, this.$refs.linkGraphPane.scrollHeight);
    },

    nodeEdgeFilter(payload) {
      this.unhideallItem();

      this.filterData = payload;
      if (payload.includes('ID')) {
        const ID = payload.indexOf('ID');
        this.filterData.splice(ID, 1);
        this.hideNodesByEdgeInclude('ID');
      }
      if (this.filterData.length !== 0) {
        this.hideNodesByEdgeNew(this.filterData);
      } else {
        this.g6_graph.updateLayout();
      }
      // payload.forEach((item) => {
      //   this.hideNodesByEdge(item);
      // });
    },

    unhideallItem() {
      this.g6_graph.getNodes().forEach((node) => {
        node.show();
      });
      //  unhide all edges
      this.g6_graph.getEdges().forEach((edge) => {
        edge.show();
      });
    },
    hideNodesByEdgeNew(edgeListArray) {
      const findEdges = this.g6_graph.findAll(
        'edge',
        (edge) => !edgeListArray.includes(edge.get('model').label),
      );
      const idTargetNodes = findEdges.map((item) => item.get('model').target);
      findEdges.forEach((item) => item.hide());

      idTargetNodes.forEach((id) => {
        const node = this.g6_graph.findById(id);
        node.hide();
      });
      this.g6_graph.updateLayout();
    },
    hideNodesByEdgeInclude(edgeName) {
      const findEdge = this.g6_graph.findAll(
        'edge',
        (edge) => !edge.get('model').label.includes(edgeName),
      );
      const idTargetNodes = findEdge.map((item) => item.get('model').target);
      findEdge.forEach((item) => item.hide());

      idTargetNodes.forEach((id) => {
        const node = this.g6_graph.findById(id);
        node.hide();
      });
    },
    showNodesByEdge(edgeName) {
      const findEdge = this.g6_graph.findAll(
        'edge',
        (edge) => edge.get('model').label === edgeName,
      );
      const idTargetNodes = findEdge.map((item) => item.get('model').target);

      findEdge.forEach((item) => item.show());

      idTargetNodes.forEach((id) => {
        const node = this.g6_graph.findById(id);
        node.show();
        this.g6_graph.paint();
      });
    },
    nodeSearchFocus(focusNodes) {
      this.g6_graph.findAllByState('node', 'searchEd').forEach((node) => {
        this.g6_graph.clearItemStates(node, 'searchEd');
      });

      this.g6_graph
        .findAllByState('node', 'searchRelatedEd')
        .forEach((node) => {
          this.g6_graph.clearItemStates(node, 'searchRelatedEd');
        });
      this.g6_graph
        .findAllByState('edge', 'searchRelatedEd')
        .forEach((edge) => {
          this.g6_graph.clearItemStates(edge, 'searchRelatedEd');
        });

      // And then get the focusItem [Array] = and then loop through it and set the focusEd state to true
      focusNodes.forEach((node) => {
        const currentNode = this.g6_graph.findById(node.id);
        this.g6_graph.setItemState(currentNode, 'searchEd', true);
      });
    },

    nodeSearchClear() {
      this.g6_graph.findAllByState('node', 'searchEd').forEach((node) => {
        this.g6_graph.clearItemStates(node, 'searchEd');
      });
    },

    nodeSearch(payload) {
      const searchNodeItem = this.g6_graph.findById(payload.id);
      // Find all focused item and clear their state.
      this.g6_graph.findAllByState('node', 'searchEd').forEach((node) => {
        this.g6_graph.clearItemStates(node, 'searchEd');
      });
      this.g6_graph
        .findAllByState('node', 'searchRelatedEd')
        .forEach((node) => {
          this.g6_graph.clearItemStates(node, 'searchRelatedEd');
        });
      this.g6_graph
        .findAllByState('edge', 'searchRelatedEd')
        .forEach((edge) => {
          this.g6_graph.clearItemStates(edge, 'searchRelatedEd');
        });
      // focus the item that you searched
      searchNodeItem.getNeighbors('node').forEach((neighbor) => {
        // this.g6_graph.setItemState(neighbor, 'selected', false);
        this.g6_graph.setItemState(neighbor, 'searchRelatedEd', true);
      });
      searchNodeItem.getOutEdges().forEach((edge) => {
        // this.g6_graph.setItemState(edge, 'selected', false);
        this.g6_graph.setItemState(edge, 'searchRelatedEd', true);
      });
      this.g6_graph.focusItem(searchNodeItem);
      const wordObj = {
        word: payload.text,
        label: payload.text,
        type: 'string',
        lang: 'en',
      };

      this.$store.dispatch('setCurrentThought', {
        thoughtObject: wordObj,
        excludeCallList: ['wikiGraphAction'],
      });

      // disable any focusEd State if there is one
      this.g6_graph.setItemState(searchNodeItem, 'searchEd', true);
    },
    videoEmbedURLFromURL(url) {
      if (url) {
        const regex = /^https?:\/\/(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\S+)?$/;
        const match = url.match(regex);
        if (match && match[1].length === 11) {
          return `https://www.youtube.com/embed/${match[1]}?rel=0`;
        }
      }
      return '';
      // ^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*
    },
    updateMyNode(val) {
      const updatedNode = this.g6_graph.findById(val.id);
      updatedNode.update(val);
    },
    async wikiGraphBuild() {
      // plugins
      const tooltip = new G6.Tooltip({
        offsetX: 10,
        offsetY: 10,
        fixToNode: [1, 0.5],
        // the types of items that allow the tooltip show up
        // 允许出现 tooltip 的 item 类型
        itemTypes: ['node', 'edge'],
        // custom the tooltip's content
        // 自定义 tooltip 内容
        getContent: (e) => {
          const outDiv = document.createElement('div');
          outDiv.style.width = 'fit-content';
          outDiv.style.height = 'fit-content';
          const model = e.item.getModel();
          if (e.item.getType() === 'node') {
            outDiv.innerHTML = `${model.label}`;
          } else {
            const source = e.item.getSource();
            const target = e.item.getTarget();
            outDiv.innerHTML = `source: ${
              source.getModel().label
            }<br/>target: ${target.getModel().label}`;
          }
          return outDiv;
        },
      });
      // this.gdata = this.$store.state.g6_graph_data;
      const config = graphConfig;
      const toolbar = new G6.ToolBar({
        position: { x: 350, y: 5 },
      });
      config.container = this.$refs.mountNode;
      config.plugins = [tooltip, toolbar];
      config.width = this.$refs.linkGraphPane.scrollWidth;
      config.height = this.$refs.linkGraphPane.scrollHeight;
      config.layout = {
        type: 'gForce',
        center: [800, 400], // The center of the graph by default
        linkDistance: 100,
        nodeStrength: 1000,
        edgeStrength: 300,
        nodeSize: 30,
        preventOverlap: true,
      };
      this.g6_graph = new G6.Graph(config);

      // Mouse enter State
      // this.g6_graph.on('node:mouseenter', (e) => {
      //   const nodeItem = e.item;
      //   // this.g6_graph.setItemState(nodeItem, 'hover', true);
      //   // eslint-disable-next-line

      // });
      // // Mouse leave State:
      // this.g6_graph.on('node:mouseleave', (e) => {
      //   const nodeItem = e.item;
      //   this.g6_graph.setItemState(nodeItem, 'hover', false);
      //   // eslint-disable-next-line

      // });

      // Click Event
      this.g6_graph.on('node:click', (e) => {
        const nodeItem = e.item;

        if (this.$store.state.gmodule.edit_mode === 'explore') {
          // eslint-disable-next-line
          const nodeId = e.item._cfg.id;

          const clickNodes = this.g6_graph.findAllByState('node', 'click');
          clickNodes.forEach((cn) => {
            this.g6_graph.setItemState(cn, 'click', false);
          });

          this.g6_graph.setItemState(nodeItem, 'click', true);
          const payload = {
            word: nodeId,
            type: 'entity',
            lang: this.$store.state.locale.currentLocale,
          };
          // eslint-disable-next-line
          this.$store.dispatch("graphExpansion", payload).then(() => {
            this.g6_graph.data(this.$store.state.g6_graph_data);
            this.g6_graph.render();
            const updatedNode = this.g6_graph.findById(nodeId);

            updatedNode.update({
              style: {
                fill: '#ffc107',
                stroke: '#fff',
              },
            });

            this.g6_graph.focusItem(updatedNode, true);
            axios.post(`${process.env.VUE_APP_ZELDA_URI}event`, {
              actor: this.$store.state.userInfo,
              verb: 'EXPLORED',
              value: 'eventData',
              dataSource: 'TJ-CLIENT',
            });
          });
        } else if (this.$store.state.gmodule.edit_mode === 'remove') {
          this.g6_graph.removeItem(nodeItem);
        } else if (this.$store.state.gmodule.edit_mode === 'hide') {
          nodeItem.hide();
        } else if (this.$store.state.gmodule.edit_modedit_modedit_mode === 'lock') {
          nodeItem.lock();
        } else if (this.$store.state.gmodule.edit_modedit_mode === 'hsl') {
          this.g6_graph.setMode('hsl');
        } else if (this.$store.state.gmodule.edit_modedit_modedit_mode === 'default') {
          this.g6_graph.setMode('default');
        } else if (this.$store.state.gmodule.edit_modedit_mode === 'looked_at') {
          // eslint-disable-next-line
          const model = nodeItem._cfg.model;

          this.content = nodeItem.getModel();
          this.formDataObj = model;
          this.$modal.show('example');
        }
      });

      this.g6_graph.data(this.$store.state.g6_graph_data);
      this.g6_graph.render();
    },
  },
};
</script>

<style scoped>
div {
  font-family: "Helvetica", sans-serif;
}
/* transparent background css */
#linkGraphPane {
  background: #576f7f;
  width: 100%;
  height: 100%;
  position: absolute;
}
</style>

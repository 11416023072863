<template>
  <form class="d-flex mx-5" @submit.prevent>
    <vue-simple-suggest
      type="search"
      :placeholder="$t('comp_graph--searchPlaceholder')"
      aria-label="Search"
      v-model.lazy="searchWord"
      :list="newList"
      :debounce="200"
      :controls="{
        selectionUp: [38, 33],
        selectionDown: [40, 34],
        select: [13, 36],
        showList: [40],
        hideList: [27, 35],
      }"
      :nullable-select="true"
      ref="searchNode"
      value-attribute="id"
      display-attribute="text"
      @suggestion-click="onSuggestClick"
      class="input-wrap node-search"
    ></vue-simple-suggest>
  </form>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css';

export default {
  data() {
    return {
      searchWord: '',
      newList: [],
    };
  },

  watch: {
    searchWord(val) {
      this.newList = this.$store.state.g6_graph_data.nodes
        .filter((node) => node.label.toLowerCase().includes(val.toLowerCase()))
        .map((node) => ({ id: node.id, text: node.label }));

      if (val !== '') {
        this.$root.$emit('g6NodeFocus', this.newList);
      } else if (val === '') {
        this.$root.$emit('g6NodeSearchClear');
      }
    },
  },

  computed: {
    g6_graph_data() {
      return this.$store.state.g6_graph_data;
    },
  },

  components: {
    VueSimpleSuggest,
  },

  methods: {
    async onSuggestClick(val) {
      setTimeout(() => {
        this.$root.$emit('g6NodeSearch', val);
      }, 100);
    },
    // onSuggestSelect(val) {

    //   this.$root.$emit('node-search', val);
    // },
    // fetchInformation() {

    // },
  },
};
</script>

<style lang="scss" scoped>
.node-search {
  margin-top: 0.625em;
  margin-left: -2.5em;
}
</style>
